@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.enter {
    animation: fade 0.7s ease-in;
}

.exit {
    animation: fade 0.7s ease-out reverse;
}

.race-title {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#001e58, #004ee9);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.race-title * {
    font-size: 100px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.race-number {
    padding: 0 20px;
}

.race-name {
    height: 100%;
    width: 1600px;
}

.race-distance {
    padding: 0 20px;
}
