.running-numbers {
    position: absolute;
    left: 120px;
    top: 128px;
    width: 320px;
    max-width: 320px;
    min-width: 320px;

    background-color: var(--blue-background);
    border-radius: 0 0 20px 20px;

    --jockey-padding: 7px;

    padding: var(--jockey-padding);
    height: calc(3 * 35px + 2 * var(--jockey-padding));

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    overflow: hidden;
}

.jockey-position:not(:first-child) {
    margin-top: var(--jockey-padding);
}

.jockey-position {
    text-transform: uppercase;
    /* for useFlipTransition */
    transition: transform 0.5s;
}

.jockey {
    height: 35px;
    border-radius: 35px;

    font-size: 26px;

    display: flex;
    justify-content: space-between;
    flex-direction: row;

    overflow: hidden;

    background-color: #efefef;
}

.name {
    flex: 1;
    min-width: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 0 10px;

    color: #000000;
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 35px;
    border-radius: 20px;
    letter-spacing: -2px;
    text-indent: -2px;
}

.number img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.price {
    min-width: 70px;
    max-width: 70px;
    width: 70px;

    padding: 0 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    background-color: #eb465b;
}
