.sponsor {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: var(--blue-background);
    position: relative;
}

.sponsor img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: absolute;
}

.enter {
    animation: grow var(--sponsor-logo-animation-duration) ease-out;
}

.exit {
    animation: grow var(--sponsor-logo-animation-duration) ease-out reverse
        calc(
            var(--sectionals-exit-duration) +
                var(--timing-box-animation-duration)
        );
}

@keyframes grow {
    from {
        transform: scale(0);
    }
}

.sponsor-enter {
    animation: flip-remove linear 0.5s forwards 0.5s;
    transform: rotateY(-90deg);
}

.sponsor-exit {
    animation: flip-left linear 0.5s;
    z-index: 1;
}

.trots-enter {
    animation: flip-remove linear 0.5s forwards 0.5s;
    transform: rotateY(90deg);
}

.trots-exit {
    animation: flip-right linear 0.5s;
    z-index: 1;
}

@keyframes flip-right {
    from {
        transform: rotateY(0);
    }
    to {
        transform: rotateY(90deg);
    }
}

@keyframes flip-left {
    from {
        transform: rotateY(0);
    }
    to {
        transform: rotateY(-90deg);
    }
}

@keyframes flip-remove {
    to {
        transform: rotateY(0);
    }
}

.bell-enter {
    animation: fade 0.5s;
}

.bell-exit {
    animation: fade 0.5s reverse;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
