.pre-race-table {
    --footer-height: 30px;
}

.pre-race-table-container {
    --price-cell-width: 120px;
    --flucs-width: calc(5 * var(--price-cell-width));
    --runners-width: 589px;
    --row-height: 37.5px;
    --venue-info-height: 43px;
    --venue-info-font-size: 33px;
    --body-headers-height: 50px;

    /* Required for auto scrolling table */
    height: calc(572px - var(--footer-height));

    overflow: hidden;
}

.aba-table-header {
}

.time-to-race {
    width: 180px;
    height: var(--venue-info-height);
    padding-right: 130px;
}

.time-to-race * {
    text-align: center;
}

.time-to-race .label {
    background-image: var(--grey-gradient);
    color: white;
    font-size: 10px;
    height: 14px;
}

.time-to-race .mins {
    background-image: var(--light-grey-gradient);
    color: black;
    font-size: 22px;
    font-weight: bolder;
    height: 29px;
}

.body-headers {
    border-top: 5px solid white;
}

.runners-header {
    font-size: 25px;
    width: var(--runners-width);
    background-image: var(--grey-gradient);
}

.flucs-header {
    font-size: 25px;
    width: var(--flucs-width);
    background-image: var(--grey-gradient);
    border-left: 2px solid white;
}

.op-header {
    font-size: 25px;
    width: var(--price-cell-width);
    background-color: var(--blue-highlight);
    border-left: 5px solid white;
    border-right: 5px solid white;
}

.tab-header {
    width: calc(2 * var(--price-cell-width));
    background-color: var(--tab-green);
}

.tab-top-header {
    font-size: 23px;
    height: 23px;
    line-height: 25px;
}

.tab-price-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.tab-price-header * {
    width: var(--price-cell-width);
    font-size: 22px;
    line-height: 22px;
    height: 25px;
}

.silk {
    width: 80px;
    border-right: 1px solid grey;
}

.op {
    border-left: 5px solid white;
    border-right: 5px solid white;
}

.scratched {
    filter: grayscale(1);
    color: #333333;
}

.aba-table-runner-row .price:nth-child(2),
.price:nth-child(7) {
    border-left: 2px solid white;
}

.max-flux-highlight {
    color: var(--blue-highlight);
}

.footer {
    height: var(--footer-height);
}
.footer * {
    color: white;
    font-size: 25px;
    font-weight: bold;
}
.market-percentage {
    float: left;
}
.past-the-post {
    float: right;
}

@keyframes flash-yellow {
    50% {
        color: yellow;
    }
}

.new-price-entered {
    animation: flash-yellow 1s infinite;
}
