.timing-sponsor {
    position: absolute;
    left: 480px;
    top: 45px;
    height: 55px;
}

@keyframes fade-slide {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }
}

.enter {
    animation: fade-slide 0.6s ease-out;
}

.exit {
    animation: fade-slide 0.6s ease-out reverse;
}
