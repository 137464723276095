@screen-width: 1920px;
@screen-height: 1080px;

@font-weight-normal: 500;
@font-weight-heavy: 900;

@default-bg-opacity: 0.85;

// Base colour for component backgrounds
@color-base: rgba(0, 19, 63, 1);
@color-base-background: rgba(0, 19, 63, @default-bg-opacity);

// Major colors used primarily for fonts
@color-primary: rgba(235, 231, 230, 1);
@color-primary-background: rgba(254, 254, 254, @default-bg-opacity);

@color-interim: #e34c3d;
@color-final: #00ff87;

@color-highlight-inverse: #fefefe;
@color-highlight: #ffb000;

.color-scheme-default {
    color: @color-primary;
    background-color: @color-base-background;
}

.color-scheme-inverse {
    color: @color-base;
    background-color: @color-primary-background;
}

.color-scheme-highlight {
    color: @color-highlight-inverse;
    background-color: @color-highlight;
}

// Default size for silks shown in lower frames
@lf-silk-box-size: 130px;
@lf-silk-size: @lf-silk-box-size * 0.9;
@lf-silk-box-padding: ((@lf-silk-box-size - @lf-silk-size) / 2);

// Size of the sponsor tile as shown in banner components (totes, dividends, race title) - square
@sponsor-tile-size: 150px;

@banner-height: @sponsor-tile-size;

// Default width of full frame components
@ff-component-width: 1400px + @sponsor-tile-size;

@font-size-banner-h1: 165px;
@font-size-banner-h2: 83px;
@font-size-banner-h3: 63px;
@font-size-banner-h4: 61px;
