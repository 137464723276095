@font-face {
    font-family: "Good Pro Condensed";
    font-weight: 500;
    src: url("assets/fonts/GoodProCondMedium.otf") format("opentype");
}

@font-face {
    font-family: "Good Pro Condensed";
    font-weight: 700;
    src: url("assets/fonts/GoodProCondBold.otf") format("opentype");
}

.sky-timing-harness {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: absolute;

    font-family: "Good Pro Condensed";
    font-weight: 500;

    --background-red: #ff0f50;
    --background-dark-red: #db003b;
}

.map-time-top-speed-container {
    --map-time-height: 150px;
    --top-speed-mile-rate-height: 50px;

    position: absolute;
    left: 83px;
    top: 834px;

    height: calc(var(--map-time-height) + var(--top-speed-mile-rate-height));
    width: 250px;

    background: linear-gradient(
        180deg,
        var(--background-red) var(--map-time-height),
        var(--background-dark-red) var(--map-time-height)
    );

    overflow: hidden;
}

.map-time {
    height: var(--map-time-height);
    width: 250px;
}

.top-speed-mile-rate {
    height: var(--top-speed-mile-rate-height);
    width: 250px;
}

.map-time-top-speed-enter {
    animation: slide-in 0.5s;
}

.map-time-top-speed-exit {
    animation: slide-in 0.5s reverse 1s;
}

@keyframes slide-in {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0);
    }
}
