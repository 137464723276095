@import "../lf.less";

.lf_protest {
    &:extend(.lf);

    div.text-container {
        max-width: calc(1920px - 2 * @lf-horizontal-margin - @lf-silk-box-size);

        .participants {
            height: 65px;

            .name {
                font-weight: bold;
            }

            .separator {
                text-transform: lowercase;
            }
        }

        .two-line {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
        }

        .second-line {
            height: 45px;

            .reason {
                margin: auto 0;
            }
        }
    }
}
