.running-numbers {
    position: absolute;
    left: 160px;
    top: 188px;
    width: 340px;
    max-width: 340px;
    min-width: 340px;

    background-color: var(--blue-background);
    border-radius: 0 0 20px 20px;

    --jockey-padding: 7px;

    padding: var(--jockey-padding);
    height: calc(3 * 40px + 2 * var(--jockey-padding));

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    overflow: hidden;
}

.jockey-position:not(:first-child) {
    margin-top: var(--jockey-padding);
}

.jockey-position {
    text-transform: uppercase;
    /* for useFlipTransition */
    transition: transform 0.5s;
}

.jockey {
    height: 40px;
    border-radius: 40px;

    font-size: 30px;

    display: flex;
    justify-content: space-between;
    flex-direction: row;

    overflow: hidden;

    background-color: #efefef;
}

.name {
    flex: 1;
    min-width: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 0 10px;

    color: #000000;
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 40px;
    border-radius: 20px;
    letter-spacing: -2px;
    text-indent: -2px;
}

.price {
    min-width: 80px;
    max-width: 80px;
    width: 80px;

    padding: 0 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    background-color: #eb465b;
}
