.top-speed {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    color: white;

    font-weight: bold;
    font-size: 19pt;
}

.top-speed > *:last-child {
    margin-right: 8px;
}

.kmh {
    font-size: 14pt;
}

.chiclet {
    height: 100%;
}

.enter {
    animation: fade-in 0.6s;
}

.exit {
    animation: fade-in 0.6s reverse;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
