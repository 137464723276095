@import "~truck-font-DINPro";

.atc-homestraight {
    width: 15360px;
    height: 192px;
    overflow: hidden;
    position: absolute;

    font-family: "DINPro";

    --background-blue: #001e58;
    --atc-light-gold: #ece7dc;
    --atc-gold: #94825b;
}

.atc-homestraight * {
    box-sizing: border-box;
}
