@import "~truck-font-DINPro";

.hrv-timing {
    font-family: "DINPro";
    font-weight: bold;

    --blue-background: rgba(54, 54, 54, 0.9);

    --sectionals-exit-duration: 0.3s;
    --mile-rate-animation-duration: 0.3s;

    width: 1920px;
    height: 1080px;
}

.timing-box-container {
    position: absolute;
    right: 96px;
    top: 108px;
    height: 80px;

    display: flex;

    --border-radius: 40px;

    --sponsor-width: 80px;
    --clock-width: 140px;
    --map-width: 130px;

    --sponsor-logo-animation-duration: 0.2s;
    --timing-box-animation-duration: 0.5s;
}

.timing-box {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    background-color: var(--blue-background);
    padding-right: calc(var(--sponsor-width) / 2);
    right: calc(var(--sponsor-width) / 2 * -1);
    position: relative;

    background: radial-gradient(
            circle at right,
            transparent var(--border-radius),
            var(--blue-background) 0
        )
        right;

    box-sizing: border-box;

    display: flex;
    justify-content: flex-end;

    transition: clip-path var(--timing-box-animation-duration);

    /* TODO we ideally only want this delay on the inital entry with the
     * sponsor logo */
    transition-delay: var(--sponsor-logo-animation-duration);
}

.timing-box-0 {
    clip-path: inset(
        0 0 0 100% round var(--border-radius) 0 0 var(--border-radius)
    );

    /* on exiting, wait for the sectionals to exit first */
    transition-delay: var(--sectionals-exit-duration);
}

.timing-box-1 {
    clip-path: inset(
        0 0 0 var(--map-width) round var(--border-radius) 0 0
            var(--border-radius)
    );
}

.timing-box-2 {
    clip-path: inset(0 round var(--border-radius) 0 0 var(--border-radius));
}

.map-and-distance {
    height: 100%;
    width: var(--map-width);
    position: relative;
}

.clock {
    width: var(--clock-width);
    padding-right: 20px;
    box-sizing: border-box;
}

.sponsor {
    width: var(--sponsor-width);
    z-index: 1;
}
