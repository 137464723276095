@import "~truck-font-DINPro";

.breezeup * {
    box-sizing: border-box;
}

.breezeup {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: absolute;

    font-family: DINPro;
    font-weight: 500;

    --bg-blue-light: #094fa3;
    --bg-blue-dark: #002b64;

    --border-radius: 10px;

    color: white;
}

.lot-info {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 20px;
}

.lot-info-left {
    left: 50px;
    align-items: flex-start;
}

.lot-info-left.enter {
    animation: slide-right 0.7s ease-in-out;
}

.lot-info-left.exit {
    animation: slide-right 0.7s reverse ease-in-out;
}

.lot-info-right {
    right: 50px;
    align-items: flex-end;
}

.lot-info-right.enter {
    animation: slide-left 0.7s ease-in-out;
}

.lot-info-right.exit {
    animation: slide-left 0.7s reverse ease-in-out;
}

.lot-and-breeding {
    background-color: var(--bg-blue-dark);
    border-radius: var(--border-radius);
    padding: 10px;
    width: 650px;
}

.lot-info-left > .lot-and-breeding:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--border-radius);
}

.lot-info-right > .lot-and-breeding:not(:last-child) {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: 0;
}

.lot {
    text-transform: uppercase;
    font-size: 50px;
}

.breeding {
    text-transform: uppercase;
    font-size: 25px;
}

.lot-time {
    background-color: var(--bg-blue-light);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    padding: 10px;
    animation: slide-down 0.7s;
    font-size: 40px;
}

.clock {
    position: absolute;
    left: 860px;
    top: 20px;
    width: 200px;

    background-color: var(--bg-blue-light);
    border-radius: var(--border-radius);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 55px;
}

.clock.enter {
    animation: slide-down 0.7s ease-in-out;
}

.clock.exit {
    animation: slide-down 0.7s reverse ease-in-out;
}

@keyframes slide-down {
    from {
        clip-path: inset(0 0 100% 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes slide-left {
    from {
        clip-path: inset(0 0 0 100%);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes slide-right {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}
