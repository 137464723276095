@import "../lf.less";

@multiplier: 1.1;
@runner-width: 240px;
@runner-lr-padding: 20px;
@runner-totalWidth: @runner-width + @runner-lr-padding * 2;

@screen-width: 1920px;
@component-width: @runner-totalWidth * 4;
@component-lr-margin: ((@screen-width - @component-width) / 2);

.lf_runningNumbers {
    position: absolute;
    width: @component-width;
    margin: 0 @component-lr-margin;
    bottom: 5px;

    .runner {
        width: 240px;
        position: absolute;
        bottom: 0;

        .silk {
            position: absolute;
            top: 0;

            height: 96px;
            width: @lf-silk-box-size;
            padding: (@lf-silk-box-padding / 2);

            display: flex;
            justify-content: center;
            align-content: center;

            z-index: 5;
            bottom: 35px;
            left: 5px;

            @silk-size: calc(96px - (@lf-silk-box-padding / 2));

            img {
                height: @silk-size;
                padding-left: 15px;
            }
        }

        .box {
            opacity: 0.85;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .color-scheme-default;

            .number {
                flex: 0 0 40%;
                text-align: center;

                font-size: 5rem;
                font-weight: 700;
                padding: 0 1rem;
            }
        }
    }

    .position-1 {
        left: @runner-lr-padding;
    }
    .position-2 {
        left: @runner-lr-padding + @runner-totalWidth;
    }
    .position-3 {
        left: @runner-lr-padding + @runner-totalWidth * 2;
    }
    .position-4 {
        left: @runner-lr-padding + @runner-totalWidth * 3;
    }
}
