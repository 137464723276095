.clock {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    height: 100%;
    width: 100%;

    font-size: 36px;
    color: #ffffff;
}
