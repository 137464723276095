@import "../../HorseRacing.less";

.past-the-post {
    @reduced-sponsor-tile-size: 110px;

    .mask,
    .title {
        margin: 0 360px;
    }

    .sponsor-tile {
        width: @reduced-sponsor-tile-size;
        height: @reduced-sponsor-tile-size;
    }

    .title {
        .title-box {
            height: @reduced-sponsor-tile-size;

            h1 {
                font-size: 126px;
                line-height: @reduced-sponsor-tile-size;
                height: @reduced-sponsor-tile-size;
                min-width: 110px;
            }

            .double-digit {
                font-size: 83px;
            }

            .meeting {
                font-size: 63px;
                line-height: 63px;
                margin-top: -7px;
            }

            .distance {
                font-size: 49px;
                line-height: 49px;
                padding: 0 0 0 33px;
            }

            .text {
                font-size: 49px;
                line-height: 49px;
                margin-top: -7px;
            }
        }

        .status-box {
            font-size: 64px;
            height: @reduced-sponsor-tile-size;
            line-height: @reduced-sponsor-tile-size;
            padding: 0 21px;
        }
    }

    div.table {
        div.row {
            padding-bottom: 20px;
            font-size: 49px;

            &.place-1 {
                padding-bottom: 0;
            }
        }
    }

    @horse-number-column-width: 109px;
    @money-column-width: 200px;

    .horses {
        .dead-heat-banner {
            height: 15px;
            margin-bottom: 14px;

            &.dead-heat {
                height: 36px;
                padding: 4px 0 8px 109px;
                margin-bottom: 15px;
                font-size: 24px;
            }
        }

        .horse-number {
            flex: 0;
            min-width: @horse-number-column-width;
            text-align: center;
        }

        .horse-jockey-trainer {
            .jockey-trainer {
                margin-top: 6px;
                height: 49px;
            }

            .jockey,
            .trainer {
                font-size: 35px;
                padding: 0 0;
            }
        }

        .win-amount {
            width: @money-column-width;
        }

        .place-amount {
            width: @money-column-width;
            padding-right: 23px;
        }
    }

    &.justify-content-bottom {
        .horses {
            margin-bottom: 25px;

            .row:last-child {
                padding-bottom: 15px;

                &.place-1 {
                    padding-bottom: 0; // Super unlikely - this would mean a triple tie for first!
                }
            }
        }
    }
}
