.track-map {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 7px;
}

.track-map svg {
    width: 100%;
    height: 100%;
}

.track {
    fill: none;
    stroke: #ffffff;
}

.horse {
    fill: none;
    stroke: #60c4ae;
}

.position-marker {
    fill: #ed0000;
}

.running-distance-text {
    fill: #ffffff;
    font-size: 20px;
    text-anchor: middle;
}
