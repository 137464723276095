@import "~truck-font-DINPro";

.aba-prices {
    width: 1549px;
    height: 572px;
    overflow: hidden;
    position: relative;

    font-family: "DINPro";
}

.aba-prices * {
    box-sizing: border-box;
}

.bottom-strip {
    width: 2564px;
    height: 148px;
    overflow: hidden;
    position: absolute;

    top: 572px;

    font-family: "DINPro";
}

.bottom-strip * {
    box-sizing: border-box;
}
