@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.aba-table-enter {
    animation: fade 1.4s ease-in;
}

.aba-table-exit {
    animation: fade 0.7s ease-out reverse;
}

.aba-table {
    --light-grey-gradient: linear-gradient(#fefefe, #b3b3b3);
    --grey-gradient: linear-gradient(#6b6b6b, #292929);
    --blue-highlight: #0095c6;
    --dark-grey: #555555;
    --tab-green: #008542;
    --row-bg-color: #000000;
    --row-bg-color-alt: var(--dark-grey);

    overflow: hidden;
    position: absolute;

    background-color: #333333;
}

.venue-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    height: var(--venue-info-height);
    background-image: url("./assets/headerphoto.png");
}

.venue-name-and-race {
    font-size: var(--venue-info-font-size);
    font-style: italic;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 2px #222222;
    width: 100%;
    height: 100%;
}

.atc-price {
    float: right;
    width: 250px;
    height: 100%;
    font-size: var(--venue-info-font-size);
    color: white;
    font-weight: bold;
    padding-right: 20px;
}

.body-headers {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: var(--body-headers-height);
}

.body-headers > * {
    text-transform: uppercase;
    text-align: center;
    height: var(--body-headers-height);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: bold;
}

.aba-table-runner-row {
    display: flex;
    flex-direction: row;
    height: var(--row-height);
}

.aba-table-runner-row * {
    color: white;
    font-weight: bold;
}

.aba-table-runner-row:nth-child(2n) {
    background-color: var(--row-bg-color-alt);
}
.aba-table-runner-row:nth-child(2n + 1) {
    background-color: var(--row-bg-color);
}

.runner {
    width: var(--runners-width);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.runner-number {
    padding: 0 20px 0 30px;
    font-size: 30px;
    width: 90px;
    text-align: center;
}

.runner-name {
    font-size: 30px;
    text-align: center;
}

.price {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 30px;
    width: var(--price-cell-width);
    border-left: 1px solid grey;
}

.silk {
    height: var(--row-height);
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.silk img {
    height: 100%;
    position: relative;
}
