.sectionals {
    position: absolute;
    left: 1640px;
    top: 155px;
    width: 200px;

    overflow: visible;

    /* for useFlipTransition */
    transition: clip-path 0.5s;

    /* behind timing box */
    z-index: -1;
}

.sectionals:empty {
    padding: 0;
}

.sectionals-exit {
    animation: close var(--sectionals-exit-duration) ease-in;
}

@keyframes close {
    from {
        clip-path: inset(0);
    }
    to {
        clip-path: inset(0 0 100% 0);
    }
}

.sectional {
    display: flex;
    justify-content: space-between;

    margin-top: 5px;

    font-size: 30px;
    color: #000000;

    height: 40px;
    width: 100%;

    box-sizing: border-box;
    padding: 0 10px;

    /* for ::before background */
    position: relative;
    z-index: 0;

    /* for useFlipTransition */
    transition: transform 0.5s;
}

.sectional::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    content: "";
    transform: skewX(-20deg);

    background-color: #ffffffaa;
}

.sectional-enter {
    animation: slide-down 0.5s;
}

@keyframes slide-down {
    from {
        transform: translateY(-100%);
    }
}

.sectional-exit {
    animation: fade-out-slide-down 0.5s;
    position: absolute;
    width: 100%;
}

@keyframes fade-out-slide-down {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

.name,
.time {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
