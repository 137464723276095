@import "../lf.less";

@font-size-commentator-pick-title: 91px;
@font-size-commentator-pick-gate: @font-size-commentator-pick-title * 0.65;

.lf_commentator-pick {
    .lf;
    .horse-pick {
        .main-line;
        margin: 0;
        padding: 0 1rem 0 0;
    }

    .gate_commentator-pick {
        font-size: @font-size-commentator-pick-gate;
        vertical-align: top;
    }
}
