.rotator {
    position: relative;
    height: 100%;
    width: 100%;

    transition: transform var(--rotate-transition-time);

    --rotate: 0deg;
    --rotate-y: 0deg;
    transform: rotateY(var(--rotate-y)) rotate(var(--rotate));
}

.rotator.flip-horizontal {
    --rotate-y: 180deg;
}

.chiclets-area {
    position: absolute;
    left: 298px;
    top: 28px;
    /* we subtract the chiclet size off the designated height and width so that
     * chiclets will always remain completely inside the required area */
    width: calc(940px - var(--chiclet-size));
    height: calc(160px - var(--chiclet-size));
}

.chiclet-container {
    position: absolute;
}

.chiclet-rotator {
    transition: transform var(--rotate-transition-time);
    transform: rotate(calc(-1 * var(--rotate))) rotateY(var(--rotate-y));
}

.distance-marker-container {
    position: absolute;
    z-index: -1000;
    transform: rotate(90deg);
    transform-origin: top left;
}

.distance-marker-rotator {
    transform: rotateY(var(--rotate-y));
}
