@import "~truck-font-DINPro";

.mrc-timing {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: relative;
    z-index: 0;

    font-family: "DINPro";
    font-weight: bold;
    --font-height-offset: 0.09em;

    --background-blue: rgb(30, 64, 104);
}
