.sectionals {
    position: absolute;
    top: 834px;
    left: 333px;
    width: 200px;
    height: 200px;

    /* On top of the chiclets map background */
    z-index: 1;

    overflow: hidden;
}

.enter {
    animation: slide-in 0.5s;
}

.exit {
    animation: slide-in 0.5s reverse;
}

.sectional {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 28px;

    width: calc(100% - 40px);
    height: 50px;

    padding: 0 20px;
}

.sectional-enter {
    animation: slide-in 0.5s;
}

.sectional-exit {
    animation: slide-in 0.5s reverse;
}

.sectional.odd {
    background-color: #f9f9f9;
}

.sectional.even {
    background-color: #f2f2f2;
}

.time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    min-width: 80px;

    text-align: right;
}

@keyframes slide-in {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0);
    }
}
