.caret-up,
.caret-down,
.caret-empty {
    height: 100%;
    display: flex;
    margin-right: 5px;
    width: 25px;
}

.caret-up {
    background-color: #ff0f50;
    clip-path: polygon(50% 33%, 100% 67%, 0 67%);
}

.caret-down {
    background-color: #00cc00;
    clip-path: polygon(50% 67%, 100% 33%, 0 33%);
}
