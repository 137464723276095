@import "../HorseRacing.less";

@lf-horizontal-margin: 186px;

.lf {
    .image-box {
        .color-scheme-default;
        position: absolute;
        height: @lf-silk-box-size;
        width: @lf-silk-box-size;
        bottom: 40px;
        left: @lf-horizontal-margin;

        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        .sponsor-tile {
            width: @lf-silk-box-size;
            height: @lf-silk-box-size;
        }

        img {
            max-height: @lf-silk-size;
            max-width: @lf-silk-size;
        }
    }

    .main-line {
        font-size: 91px;
        font-weight: 900;
        line-height: 70px;
    }

    div.text-container {
        .color-scheme-default;
        position: absolute;
        overflow: hidden;
        bottom: 40px;
        left: @lf-horizontal-margin + @lf-silk-box-size;
        height: @lf-silk-box-size;
        text-transform: uppercase;

        .text-content {
        }

        .one-line {
            .main-line;
            margin: 0;
            padding: 22px 30px 22px 8px;
        }

        .two-line {
            padding: 12px;

            .title {
                .main-line;

                margin: 0;
                padding: 0 1rem 0 0;
            }

            .text {
                //position: absolute;
                font-size: 35px;
                font-weight: 900;
                padding-left: 0.2rem;
            }
        }
    }
}
