.single-image {
    position: absolute;
    width: 1920px;
    height: 1080px;
}

.single-image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.enter {
    animation: fade 0.7s ease-in;
}

.exit {
    animation: fade 0.7s ease-out reverse;
}
