@import "../Banner.less";

.lf_race-title-container {
    position: absolute;
    top: 50px;
    left: 192px;
    right: 192px;

    .banner;
}
