.clock {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 100%;

    background-color: white;

    font-size: 40px;
}
