@import "../HorseRacing.less";

// Styles for a banner including a sponsor image and text. Width can be varied.

.banner {
    height: @sponsor-tile-size;

    .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto 0px;

        .title-box {
            .color-scheme-default;
            height: @sponsor-tile-size;
            flex: 1 1;
            overflow: hidden;

            padding: 0px 10px;

            h1 {
                font-size: @font-size-banner-h1;
                line-height: @sponsor-tile-size;
                font-weight: @font-weight-heavy;
                margin: 0;
                text-transform: uppercase;
                display: inline-block;
                height: @sponsor-tile-size;
                text-align: center;
                flex: 0;
                min-width: 150px;
            }

            .double-digit {
                font-size: 110px;
            }

            .meeting {
                font-size: @font-size-banner-h2;
                line-height: @font-size-banner-h2;
                text-transform: uppercase;
                font-weight: @font-weight-heavy;
                margin-top: -4px;
                display: inline-block;
                letter-spacing: -3px;
            }

            .distance {
                font-size: @font-size-banner-h3;
                line-height: @font-size-banner-h3;
                font-weight: @font-weight-heavy;
                padding: 0 0 0 28px;
            }

            .text {
                font-size: @font-size-banner-h3;
                line-height: @font-size-banner-h3;
                font-weight: @font-weight-normal;
                text-transform: uppercase;
                display: inline-block;
                white-space: nowrap;
                margin-top: -6px;
            }

            .title-box {
                flex: 1 1 1000px;
            }

            .title-box-inside {
                display: flex;

                .title-box-inside-right {
                    padding-top: 9px;
                    overflow: hidden;
                }
            }
        }
    }
}
