.sectionals {
    grid-area: sectionals;

    background-color: var(--background-color);

    --sectionals-padding: 7px;
    padding: var(--sectionals-padding);
    /* remove top padding to maintain constant padding between sponsor and sectionals */
    padding-top: 0;

    font-size: 30px;
    color: #ffffff;

    /* needed for useFlipTransition */
    transition: clip-path 1s;

    /* for sectional-exit to be position: absolute */
    position: relative;
}

.sectionals:empty {
    padding: 0;
}

.sectionals-exit {
    animation: close 0.5s ease-in;
}

@keyframes close {
    from {
        clip-path: inset(0);
    }
    to {
        clip-path: inset(0 0 100% 0);
    }
}

.sectional {
    display: flex;
    height: 40px;
    width: 100%;

    /* needed for useFlipTransition */
    transition: transform 1s;
}

.sectional:not(:last-child) {
    margin-bottom: 7px;
}

.sectional-enter {
    animation: slide-up 1s;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
}

.sectional-exit {
    animation: fade-out-slide-up 1s ease-in;
    position: absolute;
    width: calc(100% - var(--sectionals-padding) * 2);
}

@keyframes fade-out-slide-up {
    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.interval,
.time {
    background-color: #064d74;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.interval {
    flex: 1;
    margin-right: 7px;
}

.time {
    width: 82px;
}
