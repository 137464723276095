@import "../../HorseRacing.less";

.sponsor-tile {
    .color-scheme-default;
    width: @sponsor-tile-size;
    height: @sponsor-tile-size;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
}
