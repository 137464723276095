@import "../../HorseRacing.less";

.ff_animated-container {
    .status-box {
        font-size: 84px;
        line-height: @sponsor-tile-size;
        text-transform: uppercase;
        font-weight: 900;
        text-align: right;
        padding: 0 44px;
    }

    &.interim {
        .title {
            .status-box {
                color: @color-interim;
            }
        }

        .mask {
            .border {
                background-color: @color-interim;
            }

            .bottom {
                border-top-color: @color-interim;
            }
        }
    }

    &.final {
        .title {
            .status-box {
                color: @color-final;
            }
        }

        .mask {
            .border {
                background-color: @color-final;
            }

            .bottom {
                border-top-color: @color-final;
            }
        }
    }

    div.table {
        width: 100%;
        text-transform: uppercase;
        line-height: 2.2rem;
        font-weight: @font-weight-heavy;

        div.row {
            display: flex;
            font-size: 63px;
        }
    }

    @place-column-width: 50px;
    @place-column-margin-right: 0px;
    @horse-number-column-width: 149px;
    @money-column-width: 300px;

    @exotic-numbers-column-width: 600px;

    .horses {
        .dead-heat-banner {
            height: 46px;
            background-color: transparent;
            margin-bottom: 35px;
            padding: 7px 0 8px 150px;
            font-size: 30px;
            text-transform: uppercase;
        }

        .row {
            padding-bottom: 70px;

            &.place-1 {
                padding-bottom: 53px;
            }

            &:last-child {
                padding-bottom: 59px;
            }
        }

        .placing {
            flex: 0;
            min-width: @place-column-width;
            margin-right: @place-column-margin-right;

            .color-scheme-inverse;
            text-align: center;
        }

        .horse-number {
            flex: 0;
            min-width: @horse-number-column-width;
            text-align: center;
        }

        .horse-jockey-trainer {
            flex: 1;

            .horse-name {
                display: inline-block;
                padding: 0;
                margin-bottom: 0;
            }

            .jockey-trainer {
                margin-top: 17px;
                overflow: hidden;
                height: 60px;
            }

            .jockey,
            .trainer {
                display: inline-block;
                overflow: hidden;

                font-weight: @font-weight-normal;
                font-size: 50px;
                padding: 12px 0;
            }
        }

        .win-amount {
            width: @money-column-width - 80px;
            text-align: center;
        }

        .place-amount {
            width: @money-column-width;
            text-align: right;
            padding-right: 47px;
        }
    }

    &.interim {
        .horses {
            .dead-heat-banner {
                background-color: @color-interim;
            }
        }
    }

    &.final {
        .horses {
            .dead-heat-banner {
                color: @color-base;
                background-color: @color-final;
            }
        }
    }

    &.justify-content-bottom {
        .horses {
            margin-bottom: 12px;

            .row:last-child {
                padding-bottom: 9px;
            }
        }
    }

    .exotics {
        padding-bottom: 20px;

        .animated-scroll-table-container {
            max-height: 274px;
            overflow: hidden;
        }

        .row {
            font-weight: @font-weight-normal;
            padding: 17px 0 18px 45px;
        }

        .exotic-name {
            flex: 0;
            min-width: 642px;
        }

        .exotic-numbers {
            flex: 3;
            font-weight: @font-weight-heavy;
        }

        .exotic-payout {
            flex: 2;
            text-align: right;
            min-width: @money-column-width;
            padding-right: 47px;
        }
    }
}
