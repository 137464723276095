@import "~truck-font-DINPro";

.hrv-timing {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: absolute;

    font-family: "DINPro";
    font-weight: bold;

    z-index: 0;

    --blue-background: #004ea8ee;

    --sectionals-exit-duration: 0.3s;
    --mile-rate-animation-duration: 0.3s;
}

.timing-box-container {
    position: absolute;
    right: 96px;
    top: 75px;
    height: 80px;

    display: flex;

    --sponsor-width: 80px;
    --clock-width: 165px;
    --map-width: 140px;
    --top-speed-width: 155px;

    --sponsor-logo-animation-duration: 0.2s;
    --timing-box-animation-duration: 0.5s;
}

.timing-box {
    box-sizing: border-box;

    display: flex;
    justify-content: flex-end;

    transition: clip-path var(--timing-box-animation-duration);

    /* TODO we ideally only want this delay on the inital entry with the
     * sponsor logo */
    transition-delay: var(--sponsor-logo-animation-duration);
}

.timing-box {
    background-color: var(--blue-background);
    transform: skewX(-20deg);
}

.timing-box > * {
    transform: skewX(20deg);
}

.timing-box-0 {
    clip-path: inset(0 0 0 100%);

    /* on exiting, wait for the sectionals to exit first */
    transition-delay: var(--sectionals-exit-duration);
}

.timing-box-1 {
    clip-path: inset(0 0 0 calc(var(--top-speed-width) + var(--map-width)));
}

.timing-box-2 {
    clip-path: inset(0 0 0 calc(var(--top-speed-width)));
}

.timing-box-3 {
    clip-path: inset(0);
}

.top-speed {
    width: var(--top-speed-width);
    padding: 0 20px 0 20px;
    box-sizing: border-box;
}

.map-and-distance {
    height: 100%;
    width: var(--map-width);
    position: relative;
}

.clock {
    width: var(--clock-width);
    padding-right: 20px;
    box-sizing: border-box;
}

.sponsor {
    width: var(--sponsor-width);
    z-index: 1;
}
