.map-and-distance {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 7px;

    position: relative;

    color: white;
}

.clock-text {
    fill: white;
    font-size: 45pt;
    text-anchor: middle;
    font-variant: tabular-nums;
}

.track {
    fill: none;
    stroke: #ffffff;
}

.horse {
    fill: none;
}

.lap-1 {
    stroke: #ffff00;
}

.lap-2 {
    stroke: #ffaf00;
}

.lap-3 {
    stroke: #ffffff;
}

.lap-4 {
    stroke: #ffff00;
}

.lap-5 {
    stroke: #ffaf00;
}

.lap-6 {
    stroke: #ffffff;
}

.position-marker {
    fill: #29000b;
}

.enter {
    animation: fade-in 0.6s;
}

.exit {
    animation: fade-in 0.6s reverse 1s forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
