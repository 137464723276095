.running-numbers {
    position: absolute;
    left: 192px;
    top: 975px;
    width: 1536px;

    display: flex;
    justify-content: space-between;

    overflow: hidden;

    --flex-min-spacing: calc(140px / 3);
}

.running-numbers > * {
    margin-right: var(--flex-min-spacing);
}

.running-numbers.reverse {
    flex-direction: row-reverse;
}

.running-numbers.reverse > * {
    margin-left: var(--flex-min-spacing);
    margin-right: initial;
}

.jockey-position {
    text-transform: uppercase;
}

.position {
    height: 25px;
    width: 83px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    color: #ffffff;
    background-color: #580ab4;
}

.jockey {
    width: 349px;
    height: 40px;

    font-size: 30px;

    display: flex;

    background-color: #efefef;

    /* used to hide bottom part of silk */
    overflow: hidden;

    /* used for flip transition */
    transition: transform 1s;
}

.silk {
    width: 40px;
}

.name {
    box-sizing: border-box;
    padding: 0 5px;
    flex: 1;
    min-width: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #000000;
}

.number {
    width: 36px;
}

.price {
    width: 57px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    background-color: #088e2a;
}
