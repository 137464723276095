.mile-rate {
    position: absolute;
    left: 130px;
    top: 128px;
    width: 220px;
    height: 40px;

    padding: 5px;

    display: flex;
    justify-content: space-between;

    font-size: 30px;
    color: #000000;

    z-index: 0;
}

.mile-rate::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    content: "";

    background: var(--blue-background);
    border-radius: 0 0 20px 20px;
}

.mile-rate-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 100%;
    width: 100%;

    border-radius: 40px;

    background-color: white;

    padding: 0 10px;
}

.enter {
    animation: slide-down var(--mile-rate-animation-duration);
}

.exit {
    animation: slide-down var(--mile-rate-animation-duration) reverse;
}

@keyframes slide-down {
    from {
        clip-path: inset(0 0 100% 0);
    }
    to {
        clip-path: inset(0);
    }
}

.name,
.time {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
