@import "~@fontsource/roboto/400";
@import "~@fontsource/roboto/500";
@import "~@fontsource/roboto/700";
@import "~@fontsource/roboto/900";

.horse-racing {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: absolute;

    font-family: "Roboto";
}
