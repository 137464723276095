@font-face {
    font-family: 'DINPro';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url('./DINPro-Light.otf') format('opentype');
}

@font-face {
    font-family: 'DINPro';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('./DINPro-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'DINPro';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('./DINPro-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'DINPro';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('./DINPro-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'DINPro';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url('./DINPro-Black.otf') format('opentype');
}
