.jockey-positions {
    position: absolute;
    left: 96px;
    bottom: 110px;

    width: 1750px;
    overflow: hidden;

    display: flex;
    --gap: 18px;
}

.jockey-positions > * {
    margin-right: var(--gap);
}

.jockey-positions > *:last-child {
    margin-right: initial;
}

.jockey-positions.reverse {
    flex-direction: row-reverse;
}

.jockey-positions.reverse > * {
    margin-left: var(--gap);
    margin-right: initial;
}

.jockey-positions.reverse > *:last-child {
    margin-left: initial;
}

@keyframes fade-slide {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
}

.enter {
    animation: fade-slide 0.6s ease-out;
}

.exit {
    animation: fade-slide 0.6s ease-out reverse;
}

.jockey-position {
    min-width: 332px;
    height: 155px;

    position: relative;
    z-index: 0;

    /* for useFlipTransition */
    transition: transform 1s;
}

.silk {
    position: absolute;
    top: 0;
    left: 10px;

    height: 110px;
    width: 130px;
    object-position: center top;
    object-fit: cover;

    z-index: 1;
}

.number {
    position: absolute;
    left: 88px;
    bottom: 45px;

    width: 130px;
    height: 70px;

    box-sizing: border-box;
    padding-right: 10px;
    padding-bottom: 5px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-bottom: var(--font-height-offset);

    border-radius: 6px 6px 0 0;
    background-color: white;
    color: var(--background-blue);
    font-size: 70px;
}

.price {
    position: absolute;
    left: 225px;
    bottom: 45px;

    width: 100px;
    height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: var(--font-height-offset);

    border-radius: 6px 6px 0 0;
    background-color: rgb(255, 96, 0);
    color: white;
    font-size: 34px;
}

.name {
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 45px;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 0px 5px;
    border-radius: 10px;
    font-size: 39px;

    padding-bottom: var(--font-height-offset);

    background: var(--background-blue);
    color: white;
    text-transform: uppercase;
}
