.watermark {
    position: absolute;
    top: 54px;
    left: 1687px;
}

.watermark-img {
    width: 140px;
    height: 140px;
}

.venkman-name {
    font-size: 80px;
    fill: white;
    text-transform: uppercase;
    text-anchor: middle;
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

.enter {
    animation: fade 0.6s ease-out;
}

.exit {
    animation: fade 0.6s ease-out reverse;
}
