.mile-rate {
    position: absolute;
    left: 1430px;
    top: 160px;
    width: 200px;
    height: 40px;

    padding: 5px;

    display: flex;
    justify-content: space-between;

    font-size: 30px;
    color: #000000;

    box-sizing: border-box;
    padding: 0 10px;

    z-index: 0;
}

.mile-rate::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    content: "";
    transform: skewX(-20deg);

    background-color: #ffffffaa;
}

.enter {
    animation: slide-down var(--mile-rate-animation-duration);
}

.exit {
    animation: slide-down var(--mile-rate-animation-duration) reverse;
}

@keyframes slide-down {
    from {
        clip-path: inset(0 0 100% 0);
    }
    to {
        clip-path: inset(0);
    }
}

.name,
.time {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
