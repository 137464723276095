.post-race-table {
    --row-height: 42px;
    --venue-info-height: 50px;
    --venue-info-font-size: 35px;
    --body-headers-height: 45px;
    --title-height: 45px;

    height: 572px;
}

.weights-table {
    --price-cell-width: 200px;
    --placing-width: 149px;
    --runners-content-width: 600px;
    --runners-width: calc(var(--placing-width) + var(--runners-content-width));

    height: calc(
        var(--title-height) + var(--body-headers-height) + 4 * var(--row-height)
    );
}

.deductions-table {
    --runners-width: 669px;
    --price-cell-width: 160px;
    --scratched-width: 240px;

    /* Required for auto scrolling table */
    height: calc(
        572px - var(--venue-info-height) - 2 * var(--body-headers-height) - 4 *
            var(--row-height) - 2 * var(--title-height)
    );
}

.venue-info {
    border-bottom: 5px solid white;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--title-height);
    font-size: 27px;
    color: white;
    font-weight: bold;
    width: 100%;
    letter-spacing: 5px;
}

.runners-header {
    font-size: 25px;
    width: var(--runners-width);
    background-image: var(--grey-gradient);
}

.weights-op-header {
    font-size: 25px;
    width: var(--price-cell-width);
    background-image: var(--grey-gradient);
    border-left: 1px solid grey;
}

.weights-tab-header {
    width: calc(2 * var(--price-cell-width));
    background-color: var(--tab-green);
}

.weights-tab-price-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.weights-tab-top-header {
    font-size: 22px;
    height: 22px;
    line-height: 23px;
}

.weights-tab-price-header * {
    width: var(--price-cell-width);
    font-size: 21px;
    height: 21px;
    line-height: 22px;
}

.placing {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--placing-width);
    height: 100%;
    font-size: 30px;
    border-right: 1px solid grey;
    margin-right: 10px;
}

.price {
    font-size: 30px;
    font-weight: bold;
    width: var(--price-cell-width);
    text-align: center;
}

.deductions-first-header {
    font-size: 20px;
    width: calc(2 * var(--price-cell-width));
    background-image: var(--grey-gradient);
}

.deductions-first-type-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.deductions-first-type-header * {
    width: var(--price-cell-width);
    font-size: 12pt;
}

.deductions-placing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: var(--price-cell-width);
    background-image: var(--grey-gradient);
}

.deductions-placing-top {
    font-size: 20px;
}

.deductions-placing-bottom {
    font-size: 12pt;
}

.deductions-scratched-time-header {
    font-size: 25px;
    background-image: var(--grey-gradient);
    width: var(--scratched-width);
}

.scratched-time {
    font-size: 30px;
    width: var(--scratched-width);
    text-align: center;
}
