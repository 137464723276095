.saddle {
    width: 50px;
    height: 47px;

    /* This centers the text properly since the font is not centered */
    padding-top: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: black;
    font-size: 36px;
    color: white;
}
