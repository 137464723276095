.race-title-container {
    position: absolute;
    left: 54px;
    top: 48px;
    height: 80px;

    display: flex;

    --race-number-width: 80px;

    --race-number-animation-duration: 0.2s;
    --race-info-animation-duration: 0.5s;
}

.race-number {
    background-color: #ffffff;
    color: #000000;
    font-size: 65px;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 auto;
    width: var(--race-number-width);
    height: 100%;

    /* so that it is in front of the race info background */
    z-index: 1;
}

.race-number-enter {
    animation: grow var(--race-number-animation-duration) ease-out;
}

.race-number-exit {
    animation: grow var(--race-number-animation-duration) ease-out
        calc(
            var(--race-info-animation-duration) +
                var(--mile-rate-animation-duration)
        )
        reverse;
}

@keyframes grow {
    from {
        transform: scale(0);
    }
}

.race-info {
    color: white;
    --padding: 7px;
    padding: var(--padding);

    background: var(--blue-background);
    border-radius: 0 40px 40px 0;

    padding-left: calc(var(--padding) + var(--race-number-width) / 2);
    left: calc(var(--race-number-width) / 2 * -1);
    position: relative;
    flex: 0 0 auto;

    min-width: 400px;
}

.race-info-enter {
    animation: slide-out var(--race-info-animation-duration) ease-out
        var(--race-number-animation-duration) forwards;

    /* make sure the component isn't visible during the animation delay */
    clip-path: inset(0 100% 0 0);
}

.race-info-exit {
    animation: slide-out var(--race-info-animation-duration) ease-out reverse
        var(--mile-rate-animation-duration) forwards;
}

@keyframes slide-out {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0);
    }
}

.race-venue-and-length {
    display: flex;
    align-items: flex-end;
    line-height: 0.9;
}

.race-venue {
    font-size: 42px;
    text-transform: uppercase;

    flex: 1;
}

.race-length {
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.race-name {
    font-size: 24px;
    text-transform: uppercase;
    margin-right: 30px;
}
