@keyframes flip-live-price {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(360deg);
    }
}

.live-price {
    transform-style: preserve-3d;
    animation: flip-live-price 0.5s 1 ease-in-out;
}
