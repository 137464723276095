body {
    margin: 0;
    overflow: hidden;
}

.truck {
    position: relative;
}

.module {
    position: absolute;
}
