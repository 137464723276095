.top-speed {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
}

.title,
.value {
    font-size: 20px;
    color: #000000;

    padding: 2px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    /* for ::before background */
    position: relative;
    z-index: 0;
}

.title::before,
.value::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    content: "";
    transform: skewX(-20deg);
}

.title {
    font-weight: normal;
}

.title::before {
    background-color: #60c4ae;
}

.value::before {
    background-color: #ffffff;
}

.unit {
    font-size: 0.6em;
    align-self: flex-start;
}
