@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.enter {
    animation: fade 1.4s ease-in;
}

.exit {
    animation: fade 0.7s ease-out reverse;
}

.transition {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transition video {
    position: relative;
}
