.chiclets {
    position: absolute;
    left: 93px;
    top: 755px;
    width: 1731px;
    --chiclet-map-height: 216px;
    --chiclet-map-width: 1498px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.chiclet-map {
    width: var(--chiclet-map-width);
    height: var(--chiclet-map-height);
    border-radius: 30px;

    background-color: #00000033;

    overflow: hidden;

    --chiclet-size: 60px;
    --rotate-transition-time: 2s;
}

.sponsor-bar {
    padding-top: 5px;
    width: 1730px;
    height: 82px;
}

.enter {
    animation: fade-in 0.5s;
}

.exit {
    animation: fade-in 0.5s reverse;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

.chiclet {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;

    width: var(--chiclet-size);
    height: var(--chiclet-size);

    font-size: 30px;
}

.distance-marker {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 50px;
    color: #fafafa;
    border-bottom: 5px solid #fafafa;

    width: var(--chiclet-map-height);
}
