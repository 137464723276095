@font-face {
    font-family: "Akzidenz-Grotesk BQ Condensed";
    src: url("assets/AkzidenzGroteskBQ-Cnd.woff2") format("woff2");
}

.sky-timing {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: absolute;

    font-family: "Akzidenz-Grotesk BQ Condensed";
}

.timing-box {
    position: absolute;
    left: 1529px;
    top: 19px;

    --background-color: #012f4b;

    display: grid;
    grid:
        "map-and-distance clock-and-sponsor" 95px
        "sectionals sectionals"
        / 117px 185px;
}

.sky-one {
    left: 1525px;
    top: 119px;
}

.clock-and-sponsor {
    grid-area: clock-and-sponsor;

    background-color: var(--background-color);

    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 7px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.clock-and-sponsor-enter {
    animation: expand-from-center ease-out 0.5s;
}

.clock-and-sponsor-exit {
    animation: expand-from-center ease-out 0.5s reverse;
}

@keyframes expand-from-center {
    from {
        clip-path: inset(0 50%);
    }
    to {
        clip-path: inset(0);
    }
}
