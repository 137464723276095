.sponsor {
    height: 100%;
    width: 100%;

    background-color: var(--blue-background);
    --base-transform: skewX(-20deg);
    transform: var(--base-transform);

    position: relative;
    overflow: hidden;
}

.sponsor > * {
    --base-transform: skewX(20deg);
    transform: var(--base-transform);
}

.sponsor img {
    /* height / tan(90deg - 20deg) */
    --rhombus-overhang: calc(100% / 2.747477);

    width: calc(100% + var(--rhombus-overhang));
    height: 100%;
    object-fit: cover;

    top: 0;
    left: calc(-1 * var(--rhombus-overhang) / 2);
    position: absolute;
}

.enter {
    animation: grow var(--sponsor-logo-animation-duration) ease-out;
}

.exit {
    animation: grow var(--sponsor-logo-animation-duration) ease-out reverse
        calc(
            var(--sectionals-exit-duration) +
                var(--timing-box-animation-duration)
        );
}

@keyframes grow {
    from {
        transform: var(--base-transform) scale(0);
    }
}

.sponsor-enter {
    animation: flip-remove linear 0.5s forwards 0.5s;
    transform: var(--base-transform) rotateY(-90deg);
}

.sponsor-exit {
    animation: flip-left linear 0.5s;
    z-index: 1;
}

.main-logo-enter {
    animation: flip-remove linear 0.5s forwards 0.5s;
    transform: var(--base-transform) rotateY(90deg);
}

.main-logo-exit {
    animation: flip-right linear 0.5s;
    z-index: 1;
}

@keyframes flip-right {
    from {
        transform: var(--base-transform) rotateY(0);
    }
    to {
        transform: var(--base-transform) rotateY(90deg);
    }
}

@keyframes flip-left {
    from {
        transform: var(--base-transform) rotateY(0);
    }
    to {
        transform: var(--base-transform) rotateY(-90deg);
    }
}

@keyframes flip-remove {
    to {
        transform: var(--base-transform) rotateY(0);
    }
}

.bell-enter {
    animation: fade 0.5s;
}

.bell-exit {
    animation: fade 0.5s reverse;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
