@import "../../HorseRacing.less";

@component-spacing: 0.5rem;

.lf_topHorses {
    position: absolute;
    top: 82px;
    left: 64px;
    width: 213 + @sponsor-tile-size;
    overflow: hidden;

    .header {
        .color-scheme-default;

        margin-bottom: @component-spacing;
        display: flex;
        flex-direction: row;
        z-index: 100;

        .logo {
            flex: 0 0 147px;

            width: 138px;
            background-size: cover;

            display: flex;
            align-content: center;
            justify-content: center;

            .sponsor-tile {
                width: 147px;
                height: 147px;
            }

            .logo-content {
                padding: 0;
            }
        }

        .race-details {
            flex: 1 0 200px;

            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            text-transform: uppercase;
            font-weight: 900;

            h1 {
                font-size: 120px;
                line-height: 120px;
                text-align: center;
                margin: -0.5rem 0 0;
            }

            h3 {
                font-size: 46px;
                line-height: 45px;
                text-align: center;
                margin: -1rem 0 0;
                padding-right: 5px;
            }
        }
    }

    .runners {
        margin-bottom: @component-spacing;
        z-index: 10;
        font-size: 60px;

        .runner {
            display: flex;
            flex-direction: row;
            font-weight: 700;
            text-align: center;

            & > * {
                padding: 4px;
            }

            .runner-number {
                .color-scheme-inverse;

                flex: 0 0 120px;
            }

            .runner-price {
                .color-scheme-default;
                flex: 1 1 240px;
            }
        }
    }

    .time-to-race {
        z-index: 10;
        .color-scheme-default;

        padding: 20px 0;
        font-size: 85px;
        font-weight: 700;
        text-align: center;

        &.t-2minutes {
            color: #d80020;
        }
    }
}
