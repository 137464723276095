@import "../../HorseRacing.less";

@totes-table-padding-side: 10px;

.ff_animated-container {
    .time-to-race {
        flex: 1;

        font-weight: @font-weight-heavy;
        text-transform: uppercase;
        text-align: right;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .time-value {
            font-size: @font-size-banner-h2;
            line-height: @sponsor-tile-size;
            font-weight: 900;
            margin: 0;
            display: inline-block;
            width: 215px;
        }

        .time-measure {
            font-size: 24px;
            line-height: 24px;
            font-weight: 900;
            padding: 0 18px 0 0;
            display: block;
        }

        &.t-2minutes {
            color: #d80020;
        }
    }

    .mask .border {
        display: none;
    }

    .totes-table {
        text-transform: uppercase;
        @gate-number-width: 120px;
        @horse-name-width: 900px;
        @amount-width: (
            (
                    @ff-component-width - @gate-number-width - @horse-name-width -
                        (@totes-table-padding-side * 2) - 19px - 46.4px - 55px
                ) / 2
        );

        @row-font-size: 60px;
        max-height: 773px;
        overflow: hidden;

        .totes-table-header {
            .color-scheme-highlight;

            font-weight: @font-weight-heavy;
            font-size: 36px;
            line-height: 30px;
            height: 30px;
            padding: 1px @totes-table-padding-side 0;

            display: flex;
            flex-direction: row;

            div.gate-number {
                flex: 0 0 @gate-number-width;
            }

            div.horse-display-order {
                flex: 1 0 @horse-name-width;
                padding-left: 19px;
            }

            div.win-amount {
                flex: 1 0 @amount-width;
                text-align: right;

                &.only-win-amount {
                    padding-right: 42px;
                }
            }

            div.place-amount {
                flex: 1 0 @amount-width;
                text-align: right;
                padding-right: 55px;

                &.npp {
                    padding-right: 40px;
                }
            }
        }

        .totes-table-runners-container {
            overflow: hidden;
        }

        .totes-table-row {
            display: flex;
            flex-direction: row;
            font-size: @row-font-size;
            font-weight: @font-weight-heavy;

            @gradient-start: #001440;
            @gradient-end: #485466;

            border-bottom: 3px solid transparent;
            padding: 0 40px 0 20px;
            //border-image: linear-gradient(to right, @gradient-start, @gradient-end);
            //border-image-slice: 1;

            &:first-child {
                padding-top: 23px;
            }

            &:last-child {
                padding-bottom: 6px;
            }

            &.late-scratching {
                color: fade(@color-primary, 35%);
            }

            .win-amount,
            .place-amount {
                text-align: right;
            }

            div.win-amount,
            div.place-amount {
                flex: @amount-width 0;
                text-align: right;
                padding-right: 0.4rem;
            }
        }

        div.gate-number {
            flex: 0 0 128px;
            text-align: right;
            padding-right: 48px;
        }

        div.horse-name {
            flex: 1 1 900px;

            span {
                font-size: @row-font-size * 0.71;
                font-weight: @font-weight-normal;
                padding-left: 10px;
            }
        }
    }
}
