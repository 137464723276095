.chiclet-map {
    position: absolute;
    left: 209px;
    top: 824px;
    width: 1498px;
    --chiclet-map-height: 216px;
    height: var(--chiclet-map-height);
    border-radius: 30px;

    background-color: #00000033;

    overflow: hidden;

    --chiclet-size: 60px;
    --rotate-transition-time: 2s;
}

.enter {
    animation: fade-in 0.5s;
}

.exit {
    animation: fade-in 0.5s reverse;
}

@keyframes fade-in {
    from {
        top: 950px;
        opacity: 0;
    }
    to {
        top: 824px;
        opacity: 1;
    }
}

.chiclet {
    width: var(--chiclet-size);
    height: var(--chiclet-size);
}

.distance-marker {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 50px;
    color: #fafafa;
    border-bottom: 5px solid #fafafa;

    width: var(--chiclet-map-height);
}
