.sectionals {
    position: absolute;
    left: 1515px;
    top: 188px;
    width: 235px;

    background-color: var(--blue-background);
    border-radius: 0 0 20px 20px;

    --sectionals-padding: 7px;
    padding: var(--sectionals-padding);

    /* for useFlipTransition */
    transition: clip-path 0.5s;

    /* behind timing box */
    z-index: -1;
}

.sectionals:empty {
    padding: 0;
}

.sectional:not(:first-child) {
    margin-top: var(--sectionals-padding);
}

.sectional + .sectional-exit {
    margin-bottom: 0;
}

.sectional {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    background-color: #ffffff;
    border-radius: 40px;

    font-size: 30px;
    color: #000000;

    height: 40px;
    width: 100%;

    box-sizing: border-box;
    /* padding-right: 10px; */
    overflow: hidden;

    /* for useFlipTransition */
    transition: transform 0.5s;
}

.leader {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    width: 40px;
    height: 40px;
}

.time {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.distance {
    min-width: 80px;
    max-width: 80px;
    width: 80px;

    padding: 0 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    background-color: #eb465b;
}
