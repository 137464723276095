.running-stages {
    position: absolute;
    top: 90px;
    left: 120px;
    width: 340px;

    font-size: 37px;
}

.stage {
    display: flex;
    /* for useFlipTransition */
    transition: transform 0.5s;
}

.stage > * {
    border: 4px solid black;
    height: 49px;
    border-top: none;
    border-right: none;
}

.stage > *:last-child {
    border-right: 4px solid black;
}

.stage:first-child > * {
    border-top: 4px solid black;
}

.stage:last-child > *:first-child {
    border-bottom-left-radius: 8px;
}

.stage:last-child > *:last-child {
    border-bottom-right-radius: 8px;
}

.distance,
.time {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex: 1;

    padding-right: 7px;

    background: var(--background-white);
}

.leader {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 54px;

    background: #1b1b1bd8;
}

.leader-number {
    width: 36px;
    height: 36px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    font-size: 32px;
    font-weight: bold;
    color: #000000;

    background: radial-gradient(
        circle at 30% 30%,
        #ffffff 0%,
        #f9f9f9 25%,
        #acacac 70%,
        #5c5c5c 100%
    );
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

.enter {
    animation: fade 1.2s ease-out;
}

.exit {
    animation: fade 1.2s ease-out reverse;
}

@keyframes slide {
    from {
        transform: translateY(-100%);
    }
}

.stage-enter {
    animation: fade 0.5s ease-out, slide 0.5s;
}

.stage-exit {
    animation: fade 0.5s ease-out reverse;
}
