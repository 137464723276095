.clock-and-distance {
    position: absolute;
    left: 96px;
    top: 54px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 348px;
    height: 48px;

    box-sizing: border-box;
    padding: 0 15px;

    padding-bottom: var(--font-height-offset);

    margin-right: 24px;

    border-radius: 6px;
    background-color: rgb(10, 21, 34);
    color: white;
    font-size: 42px;
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

.enter {
    animation: fade 1.2s ease-out;
}

.exit {
    animation: fade 1.2s ease-out reverse;
}
