.overall-positions {
    position: absolute;
    left: 0px;
    bottom: 29px;

    width: 1920px;
    height: 54px;

    background-color: rgba(42, 77, 168, 0.6);

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    --gap: 12px;
}

.overall-positions > * {
    margin-right: var(--gap);
}

.overall-positions > *:last-child {
    margin-right: initial;
}

.overall-positions.reverse {
    flex-direction: row-reverse;
}

.overall-positions.reverse > * {
    margin-left: var(--gap);
    margin-right: initial;
}

.overall-positions > *:last-child {
    margin-left: initial;
}

@keyframes fade-slide {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
}

.enter {
    animation: fade-slide 0.6s ease-out;
}

.exit {
    animation: fade-slide 0.6s ease-out reverse;
}

.horse-number {
    width: 60px;
    height: 46px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: var(--font-height-offset);

    background-color: white;
    border-radius: 6px;
    color: rgb(30, 64, 104);
    font-size: 48px;

    /* for useFlipTransition */
    transition: transform 1s;
}
