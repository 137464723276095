.weather {
    position: absolute;

    top: 760px;
    left: 100px;

    width: 1000px;
    height: 200px;
    overflow: hidden;

    background-color: #6c30c2;
    color: white;

    display: flex;
    flex-direction: row;
}

.weather-details {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.venue {
    width: 200px;
    font-size: 60px;
    font-weight: bold;
    padding: 15px;
}

.track-information {
    margin-left: auto;
    padding: 10px;
}

.track-rating {
    font-size: 60px;
    font-weight: bold;
    text-align: right;
}

.rail-position {
    font-size: 35px;
    width: 350px;
    text-align: right;
}

.weather-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

    height: 30px;
    font-size: 20px;
    background-color: #363f49;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    background-color: #2a2e32;
    padding: 5px;
}

.track-oval-icon {
    width: 20px;
    height: 8px;
    border-radius: 10px;
    border: 2px solid white;
    transform: rotate(-45deg);
}

.text {
    width: 80px;
    padding: 5px;
}

.wind {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.compass {
    width: 20px;
    height: 20px;
}

.compass-background {
    position: absolute;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    background-color: white;
}

.compass-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 15px;
    color: black;
}
