.sponsor-cycle {
    --scale: 0.9;
    --screen-width: 100%;
    --screen-height: 100%;
    --component-width: var(--screen-width) * var(--scale);
    --component-height: var(--screen-height) * var(--scale);
    --image-animation-time-seconds: 0.32s;
    --enter-exit-animation-time: 0.7s;
}

.layer {
    background: transparent;
    position: absolute;
    overflow: hidden;
}

.layer > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.layer.full-screen {
    width: var(--screen-width);
    height: var(--screen-height);
    top: 0;
    left: 0;
}

.layer.windowed {
    width: var(--component-width);
    height: var(--component-height);
    top: ((var(--screen-height) - var(--component-height)) / 2);
    left: ((var(--screen-width) - var(--component-width)) / 2);
}

.layer.windowed > img {
    max-width: var(--component-width);
    max-height: var(--component-height);
}

.layer.hidden {
    opacity: 0;
}

.slide-enter {
    animation: fade-in var(--image-animation-time-seconds) ease-in;
}

.slide-exit {
    /* Delay the exit time so old image disappears after new one at full opacity */
    animation: fade-out var(--image-animation-time-seconds) ease-out
        var(--image-animation-time-seconds);
}

.enter {
    animation: fade-in var(--enter-exit-animation-time) ease-in;
}

.exit {
    /* Delay the exit time so moving between ff_sponsorCycle and ff_raceSponsor won't show background */
    animation: fade-out var(--enter-exit-animation-time) ease-out
        var(--enter-exit-animation-time);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
