.map-and-distance {
    grid-area: map-and-distance;
    background: var(--background-color);

    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 7px;

    position: relative;
}

.running-distance-text {
    fill: white;
    font-size: 23px;
    text-anchor: middle;
}

.track {
    fill: none;
    stroke: #ffffff;
}

.horse {
    fill: none;
    stroke: var(--red-highlight);
}

.lap-1 {
    stroke: #a71f30;
}

.lap-2 {
    stroke: #861926;
}

.lap-3 {
    stroke: #64131d;
}

.lap-4 {
    stroke: #430c13;
}

.lap-5 {
    stroke: #21060a;
}

.position-marker {
    fill: #eb465b;
    stroke: white;
    stroke-width: 2.5px;
}

.map-and-distance-enter {
    animation: expand-from-right ease-out 0.5s;
}

.map-and-distance-exit {
    animation: expand-from-right ease-out 0.5s reverse;
}

@keyframes expand-from-right {
    from {
        clip-path: inset(0 0 0 100%);
    }
    to {
        clip-path: inset(0);
    }
}
