@import "../../Banner.less";

.ff_animated-container {
    .banner;

    @component-width: @ff-component-width;
    display: flex;
    width: 100%;
    height: 100%;

    flex-direction: column;

    &.justify-content-top {
        justify-content: flex-start;
        padding-top: 50px;
    }

    &.justify-content-center {
        justify-content: center;
    }

    &.justify-content-bottom {
        justify-content: flex-end;
    }

    .title {
        margin-left: 192px;
        margin-right: 192px;

        .status-box {
            .color-scheme-default;
            height: @sponsor-tile-size;
        }

        .status-box {
            display: inline-block;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            h2 {
                font-size: 64px;
                line-height: 106px;
                font-weight: 900;
                margin: 0;
                padding: 0 1rem;
                text-transform: uppercase;
                text-align: right;
            }
        }
    }

    .mask {
        margin: 0px 192px;
        overflow: hidden;

        .border {
            height: 30px;
        }

        .bottom {
            .color-scheme-default;
            // gradient from : #0E2524
            border-top: 5px solid @color-highlight;

            padding: 10px 0;
            font-size: 36px;
            line-height: 26px;
            text-align: center;
            font-weight: 500;
            height: 50px;

            &.interim {
                border-top-color: @color-interim;
            }

            &.final {
                border-top-color: @color-final;
            }
        }
    }

    .mask-content {
        .color-scheme-default;
    }
}
