@import "../../HorseRacing.less";

@component-width: 1540px;
@component-height: 220px;
@font-size-large: 4.2rem;
@font-size-default: 2.2rem;

@silk-box-width: 200px;
@ticker-duration: 10s;

.lf_yard-page {
    @color-base-background: rgba(0, 19, 63, 0.7);
    @color-primary-background: rgba(254, 254, 254, 0.8);

    .color-scheme-default {
        color: @color-primary;
        background-color: @color-base-background;
    }

    .color-scheme-inverse {
        color: @color-base;
        background-color: @color-primary-background;
    }

    position: absolute;
    left: ((1920px - @component-width) / 2);
    bottom: 10px;
    width: @component-width;
    height: @component-height;

    .font-large {
        font-size: @font-size-large;
        font-weight: @font-weight-heavy;
        padding-top: @font-size-large * 0.2;
        padding-bottom: @font-size-large * 0.2;
    }

    .silk {
        .color-scheme-default;

        position: relative;
        left: 0;
        width: @silk-box-width;
        height: @component-height;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: @silk-box-width;
        }
    }

    .two-rows {
        display: flex;
        flex-direction: column;

        .row {
            flex: 1;
        }
    }

    .content-container {
        .color-scheme-default;
        position: absolute;
        top: 0;
        left: @silk-box-width;
        width: @component-width - @silk-box-width;
        height: @component-height;

        display: flex;
        flex-direction: row;
    }

    .content {
        flex: 1 1;

        text-transform: uppercase;

        .row {
            padding-left: (@font-size-large / 2);
            margin-right: 1rem;
        }

        // Top row
        .horse-details {
            display: flex;

            .horse-number {
                padding-right: (@font-size-large / 2);
                flex: 0;
            }

            @name-height: 80px;

            .horse-name-container {
                flex: 1 1 80%;
                overflow: hidden;
                height: @name-height;
                max-width: 850px;

                .horse-name {
                    display: inline-block;
                    white-space: nowrap;
                    height: @name-height;
                }
            }

            .gate {
                flex: 0 1 105px;
                font-size: @font-size-large * 0.65;
                padding: 0.85rem 0 0 1.5rem;
                font-weight: @font-weight-normal;
            }
        }

        // Bottom row
        .stats {
            text-transform: uppercase;
            font-size: @font-size-default;
            font-weight: @font-weight-normal;

            .two-rows;

            .row {
                padding-left: 0;
                padding-right: 0;

                .col {
                    padding: 0.6rem 0 0;
                    text-align: left;
                }

                .breeding {
                    width: 1040px;
                }
            }
        }
    }

    &.page-0 .content .stats .row {
        span {
            display: inline-block;
        }

        &:first-child {
            .col > span:first-child {
                padding-right: 100px;
            }
        }

        &:last-child {
            .col > span:first-child {
                padding-right: 25px;
            }
        }
    }

    &.page-1 .content .stats .row {
        display: flex;
        flex-direction: row;

        .col {
            &:nth-child(1) {
                flex: 4 0;
            }

            &:nth-child(2) {
                flex: 6 1;
            }

            &:nth-child(3) {
                flex: 3.5 0;
            }

            &:nth-child(4) {
                flex: 8 1;
            }
        }
    }

    .prices {
        flex: 0 0 220px;
        text-align: right;
        padding-right: (@font-size-large / 5);
    }
}
