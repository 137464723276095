.chiclet-map {
    position: absolute;
    left: 192px;
    top: 752px;
    width: 1536px;
    --chiclet-map-height: 216px;
    height: var(--chiclet-map-height);

    background-color: #7f7f7f80;

    overflow: hidden;

    --chiclet-size: 40px;
    --rotate-transition-time: 2s;
}

.chiclet {
    width: var(--chiclet-size);
    height: var(--chiclet-size);

    font-size: 30px;
}

.distance-marker {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 50px;
    color: #fafafa;
    border-bottom: 5px solid #fafafa;

    width: var(--chiclet-map-height);
}
