.map-and-distance {
    grid-area: map-and-distance;
    background-color: var(--background-color);

    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 7px;

    position: relative;
}

.running-distance-text {
    fill: white;
    font-size: 27px;
    text-anchor: middle;
}

.track {
    fill: none;
    stroke: #ffffff;
}

.horse {
    fill: none;
    stroke: #ffe066;
}

.position-marker {
    fill: #e90002;
}

.map-and-distance-enter {
    animation: expand-from-right ease-out 0.5s;
}

.map-and-distance-exit {
    animation: expand-from-right ease-out 0.5s reverse;
}

@keyframes expand-from-right {
    from {
        clip-path: inset(0 0 0 100%);
    }
    to {
        clip-path: inset(0);
    }
}
