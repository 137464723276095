.mile-rate {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    color: white;

    font-weight: bold;
    font-size: 20pt;
}

.name,
.time {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.enter {
    animation: fade-in 0.6s;
}

.exit {
    animation: fade-in 0.6s reverse 1s forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
