.running-clock {
    text-align: right;
    flex: 1;
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

.enter {
    animation: fade 0.24s ease-out;
}

.exit {
    animation: fade 0.24s ease-out reverse;
}
