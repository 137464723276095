.jockey-positions {
    position: absolute;
    left: 620px;
    bottom: 90px;
    height: 180px;
    width: 1180px;

    display: flex;
    overflow: hidden;
}

@keyframes fade-slide {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
}

.enter {
    animation: fade-slide 0.6s ease-out;
}

.exit {
    animation: fade-slide 0.6s ease-out reverse;
}

.jockey-position {
    min-width: 220px;
    height: 180px;

    border: 4px solid black;
    border-radius: 10px;

    margin-right: 20px;

    transition: transform 1s;
}

.jockey-details {
    display: flex;
    justify-content: space-around;
    align-items: center;

    height: 88px;
    padding-left: 15px;
    background-color: #1b1b1bd8;

    overflow: hidden;
}

.silk {
    width: 85px;
    align-self: flex-start;
}

.number {
    width: 64px;
    height: 64px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 4px solid black;
    border-radius: 50%;

    font-weight: bold;
    font-size: 42px;

    background: radial-gradient(
        circle at 30% 30%,
        #ffffff 0%,
        #f9f9f9 25%,
        #acacac 70%,
        #5c5c5c 100%
    );
}

.position-details {
    height: 50px;
    display: flex;

    border-style: solid;
    border-width: 4px 0px;

    font-size: 38px;
}

.rank {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 71px;

    border-right: 4px solid black;

    color: white;
    background: var(--background-blue);
}

.position {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    flex-grow: 1;

    background: var(--background-white);
}

.name {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background: var(--background-white);
}
