.track-map {
    position: absolute;
    bottom: 25px;
    left: 75px;
    height: 400px;
    width: 400px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

@keyframes fade-slide {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
}

.enter {
    animation: fade-slide 0.6s ease-out;
}

.exit {
    animation: fade-slide 0.6s ease-out reverse;
}

.track {
    fill: none;
    stroke: #d0d9e4;
}

.track-outline {
    fill: none;
    stroke: #040404;
}

.start-marker {
    fill: #040404;
}

.finish-marker {
    fill: #e23134;
}

.finish-text {
    font-size: 25px;
    text-anchor: middle;

    fill: #fafafa;
    stroke: #040404;
    stroke-width: 1;
}

.horse {
    fill: none;
    stroke: #4b567c;
}

.position-marker {
    fill: radial-gradient(white, black);
}
