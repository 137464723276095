.race-title {
    position: absolute;
    top: 0;
    left: 290px;
    width: 1550px;
    height: 155px;
    background-image: linear-gradient(
        90deg,
        rgb(235, 1, 65, 1) 85%,
        rgb(255, 98, 56) 100%
    );
    color: white;

    display: flex;
    flex-direction: row;
    align-items: flex-end;

    text-transform: uppercase;
    font-weight: bold;
}

.race-number {
    font-size: 105px;
    margin-left: 40px;
    margin-right: 90px;
}

.lines {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.top-line {
    font-size: 60px;
    display: flex;
    flex-direction: row;
}

.race-length {
    margin-left: 50px;
}

.bottom-line {
    font-size: 40px;
    display: flex;
    flex-direction: row;

    margin-top: -8px;
}

.track-condition {
    margin-left: 120px;
}

.enter {
    animation: slide-in 0.5s;
}

.exit {
    animation: slide-in 0.5s reverse;
}

@keyframes slide-in {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0);
    }
}
