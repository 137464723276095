.chiclet-map-container {
    --sectionals-width: 200px;
    --chiclet-map-width: 1304px;

    position: absolute;
    left: calc(533px - var(--sectionals-width));
    top: 834px;
    width: calc(var(--chiclet-map-width) + var(--sectionals-width));
    --chiclet-map-height: 200px;
    height: var(--chiclet-map-height);

    background-color: #00000033;

    overflow: hidden;

    --chiclet-size: 50px;
    --rotate-transition-time: 2s;

    /* Under the sectionals as a background */
    z-index: 0;
}

.chiclet-map {
    position: relative;
    left: var(--sectionals-width);

    width: var(--chiclet-map-width);
    height: var(--chiclet-map-height);

    overflow: hidden;
}

.enter {
    animation: slide-in 0.5s;
}

.exit {
    animation: slide-in 0.5s reverse;
}

@keyframes slide-in {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0);
    }
}

.chiclet {
    width: var(--chiclet-size);
    height: var(--chiclet-size);
}

.distance-marker {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 50px;
    color: #fafafa;
    border-bottom: 5px solid #fafafa;

    width: var(--chiclet-map-height);
}
