.top-speed {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 130px;

    margin-left: 25px;
}

.title,
.value {
    font-size: 20px;

    padding: 2px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    /* for ::before background */
    position: relative;
    z-index: 0;
}

.title::before,
.value::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    content: "";

    border-radius: 40px;
}

.value {
    color: black;
}

.title {
    color: white;
    font-weight: normal;
}

.title::before {
    background-color: var(--red-highlight);
}

.value::before {
    background-color: #ffffff;
}

.unit {
    font-size: 0.6em;
    align-self: flex-start;
}

.enter {
    animation: fade-in 0.3s;
}

.exit {
    animation: fade-in 0.3s reverse;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
