@import "~truck-font-DINPro";

.atc-timing {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: absolute;

    font-family: "DINPro";
    font-weight: bold;

    --background-blue: linear-gradient(
        180deg,
        rgba(21, 53, 86, 1) 0%,
        rgba(44, 76, 114, 1) 40%,
        rgba(44, 76, 114, 1) 60%,
        rgba(21, 53, 86, 1) 100%
    );

    --background-white: linear-gradient(
        180deg,
        rgba(194, 194, 192, 1) 0%,
        rgba(250, 250, 250, 1) 20%,
        rgba(250, 250, 250, 1) 80%,
        rgba(194, 194, 192, 1) 100%
    );
}

.atc-timing * {
    box-sizing: border-box;
}

.distance-and-clock {
    position: absolute;
    top: 45px;
    left: 120px;

    width: 340px;
    height: 49px;

    display: flex;
    align-items: center;

    border: 4px solid black;
    border-radius: 8px;

    background: var(--background-blue);

    padding: 7px;

    color: white;
    font-size: 37px;
}

@keyframes fade-slide {
    from {
        opacity: 0;
        transform: translateY(60px);
    }
}

.distance-and-clock-enter {
    animation: fade-slide 0.6s ease-out;
}

.distance-and-clock-exit {
    animation: fade-slide 0.24s ease-out reverse;
}
