@import "../../HorseRacing.less";

@layer-background: #002b82;

// @scale: 0.9;
@scale: 1;
@component-width: @screen-width * @scale;
@component-height: @screen-height * @scale;

@font-face {
    font-family: "Gotham-Book";
    src: url("fonts/Gotham-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "RaisonnePro-Light";
    src: url("fonts/RaisonnePro-Light.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.ff_winning-owner {
    .bg_carnival {
        background-color: #ece7dc;
        .content {
            color: #002554;
        }
        .horse-name {
            color: #a08032;

            svg text {
                fill: #a08032;
            }
        }
    }
    .bg_regular {
        background-color: #002554;
        .content {
            color: #ffffff;
        }
        .horse-name {
            color: #ae965d;

            svg text {
                fill: #ae965d;
            }
        }
    }

    .layer {
        width: @component-width;
        height: @component-height;

        position: absolute;
        overflow: hidden;
    }

    .layer-1 {
        .layer;

        //background-color: @layer-background;
        z-index: 10;

        top: ((@screen-height - @component-height) / 2);
        left: ((@screen-width - @component-width) / 2);
    }

    .layer-2 {
        .layer;
        z-index: 20;

        display: flex;
        justify-content: flex-end;
        img {
            //position: absolute;
            //right: -135px;
            align-self: center;
            max-height: 100%;
        }
    }

    .layer-3 {
        .layer;
        z-index: 30;
        display: none;
    }

    .layer-4 {
        .layer;
        z-index: 40;
        padding-left: 100px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-end;
        img {
            align-self: flex-start;
            margin-bottom: 50px;
        }
    }

    .content {
        .layer;
        z-index: 50;
        top: 20px;
        padding-left: 100px;

        .subtitle,
        .congratulations {
            font-family: "Gotham-Book";
            font-size: 65px;
            font-weight: 300;
            text-transform: uppercase;
        }

        .congratulations {
            margin: 0;
            margin-top: 40px;
        }

        .subtitle {
            margin: 0;
        }

        .horse-name {
            font-family: "RaisonnePro-Light";
            font-weight: normal;
            text-transform: uppercase;

            height: 190px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            &.large {
                font-size: 180px;
            }

            &.medium {
                font-size: 180px;
            }

            &.small {
                font-size: 150px;
            }
        }

        .owner-names {
            padding-right: 580px;
            font-family: "Gotham-Book";
            font-weight: 400;

            &.large {
                font-size: 4rem * @scale;
            }

            &.medium {
                font-size: 3.5rem * @scale;
            }

            &.small {
                font-size: 2.5rem * @scale;
            }
        }

        .coolmore {
            position: absolute;
            top: 760px;
        }

        .coolmore-sire {
            font-size: 48px;
            font-family: "Gotham-Book";

            .sire-name {
                color: rgb(165, 135, 61);
            }
        }
    }
}
