.jockey-list {
    background-color: var(--background-blue);
    background-image: repeating-linear-gradient(
        -45deg,
        #001e5a 10%,
        #002197 20%,
        #001e5a 30%
    );
    width: 100%;
    height: 192px;
}

.jockey-profile {
    display: flex;
    flex-direction: row;
    width: 1280px;
    height: 192px;
    float: left;
}
.jockey-scratched {
    filter: grayscale(1);
}

.silk {
    width: 246px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.silk img {
    height: 100%;
    position: relative;
}

.silk-enter {
    animation: silkfromleft 1s ease-out;
}

@keyframes silkfromleft {
    from {
        left: -20px;
        filter: brightness(1.7);
    }
    to {
        left: 0;
        filter: brightness(1);
    }
}

.jockey-info {
    width: 100%;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px 10px 40px;
    color: var(--atc-light-gold);
    overflow: hidden;
}
.jockey-info-enter {
    clip-path: inset(0 100% 0 0);
    animation: linearwipe 0.6s ease-out forwards;
    animation-delay: 1s;
}
@keyframes linearwipe {
    to {
        clip-path: inset(0);
    }
}

.jockey-info-name-and-driver {
    width: 60%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.jockey-info-price {
    width: 35%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.jockey-info-name {
    width: 100%;
    height: 90px;
}

.jockey-info-driver {
    width: 100%;
    height: 50px;
}

.caret-up,
.caret-down,
.caret-empty {
    height: 100%;
    display: flex;
    margin-right: 15px;
    width: 30px;
}

.caret-up {
    background-color: #00ae27;
    clip-path: polygon(50% 40%, 100% 60%, 0 60%);
}

.caret-down {
    background-color: #e00000;
    clip-path: polygon(50% 60%, 100% 40%, 0 40%);
}
