.sectionals {
    position: absolute;

    display: flex;

    left: 468px;
    top: 54px;
}

.sectional-container {
    margin-right: 24px;

    /* for useFlipTransition */
    transition: transform 0.5s;
}

.sectional {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 320px;
    height: 48px;
    overflow: hidden;

    box-sizing: border-box;
    padding: 0 15px;
    border-radius: 6px;

    padding-bottom: var(--font-height-offset);

    color: white;
    font-size: 42px;
}

.with-comparison-1200 {
    border-radius: 6px 6px 0 0;
}

.similar {
    background-color: var(--background-blue);
}

.slow {
    background-color: #db0000;
}

.fast {
    background-color: #3f8600;
}

.time {
    align-self: flex-start;
    height: 100%;
    transition: transform 500ms ease-out;
}

.time.show-delta {
    transform: translateY(-100%);
}

.time > * {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.comparison {
    float: right;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 120px;
    padding-bottom: var(--font-height-offset);

    background-color: #000000bb;
    border-radius: 0 0 6px 6px;
    font-size: 32px;
    color: white;
}

.comparison-1200 {
    justify-content: space-around;
    width: 320px;
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

.enter {
    animation: fade 1.2s ease-out;
    /* make sure it appears behind anything else while animating */
    z-index: -1;
}

.exit {
    animation: fade 1.2s ease-out reverse;
}

@keyframes slide {
    from {
        transform: translateX(-100%);
    }
}

.sectional-enter {
    animation: fade 0.5s ease-out, slide 0.5s;
}

.sectional-exit {
    animation: fade 0.5s ease-out reverse;
}
