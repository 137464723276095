@import "../lf.less";

.lf_photo_all_placings {
    .lf;

    div.text-container {
        padding: 0.3rem 0 0 1rem;

        .text {
            font-size: @lf-silk-box-size * 0.9;
            margin: -0.5rem 0 0;
            padding: 0 1rem 0 0;
        }
    }
}
