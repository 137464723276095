.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.background-enter {
    animation: fade-in 0.4s ease-in;
}

.background-exit {
    animation: fade-out 0.4s ease-out;
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
